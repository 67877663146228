import { TeamDetails } from 'pages/Team/ViewTeamPage/TeamDetails';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { ITeam } from 'services/providers/TeamsProvider/types';
import { TeamsService } from 'services';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { Col, Form, Row, Tabs, Tooltip, Typography } from 'antd';
import { useAuth } from 'services/providers/AuthProvider';
import React, { useState, ReactNode } from 'react';
import { ModalViewEmployees } from 'pages/Team/ViewTeamPage/ModalViewEmployees';
import TableComponent from 'components/Table';
import type { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import {
  allowPermision,
  rolesAllow,
  adminRoles,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { useModalContext } from 'components/Modal/ModalProvider';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import ConfirmModalComponent from 'components/Modal/ConfirmModal';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import removeMemberIcon from 'assets/removeMember.svg';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { getCurrentEmployeePosition } from 'components/Employee/EmployeeForm/utils';
import { Link, useSearchParams } from 'react-router-dom';

import { BaseButton, ButtonSimple } from 'components/Buttons';
import ModalComponent from 'components/Modal';
import { useTeamViewContext } from 'components/Team/TeamViewContext/TeamViewContext';
import { columnsMobileTeamView } from './columnsMobileTeamView';
import dayjs from 'dayjs';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { getTitleColumns } from 'components/TitlesColumns/utils';
import { titlesKeysofColumnsMembers } from './utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ViewMemberForm from './ViewMemberForm';
import { AddUserSvg } from 'Icons/addUserComponent';
import { EditIconSimpleSvg } from 'Icons/EditIconSimple';
import TextPhoneInput from 'components/UIComponents/InputPhoneComponent/TextPhoneInput';
import {
  EditTeamFormPlaceholder,
  MobileEditTeamPlaceholder,
  TableComponentPlaceholder
} from 'components/Placeholders';
import ThemedIcon from 'components/UIComponents/Icon';
import { UserProfileMinus } from 'Icons/Teams/UserProfileMinus';
import ViewTeamImage from './ViewTeamImage/ViewTeamImage';
import { ModalAddContact } from './ViewTeamImage/ModalAddContact';
import { useContactColumns } from './useContactColumns';

const columns = (
  data = null,
  role = null,
  params,
  isModalOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const { Text } = Typography;
  const tableColumns = [
    {
      title: getTitleColumns('employeeName', 'members'),
      dataIndex: 'employeeName',
      width: '20%',
      render: (_, record) => {
        return (
          <div className="line-clamp-1">
            <Tooltip placement="top" title={record?.employeeName}>
              {allowPermision(role, rolesAllow) ? (
                <Link
                  to={`/allUsersList/viewProfile?employeeId=${record.employeeId}`}
                >
                  {record?.employeeName}
                </Link>
              ) : (
                <p>{record?.employeeName}</p>
              )}
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: getTitleColumns('employeePosition', 'members'),
      dataIndex: 'employeePosition',
      width: '20%',
      render: (_, record) => (
        <div
          style={{
            display: 'flex'
          }}
        >
          <span className="line-clamp-1">
            <FormattedMessage id={record?.employeePosition} />
          </span>
        </div>
      )
    },
    {
      title: getTitleColumns('phoneNumber', 'members'),
      dataIndex: 'phoneNumber',
      width: '15%',
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={<TextPhoneInput crudPhone={record.phoneNumber} />}
        >
          <span className="line-clamp-1">
            {' '}
            <TextPhoneInput crudPhone={record.phoneNumber} />
          </span>
        </Tooltip>
      )
    },

    {
      title: getTitleColumns('email', 'members'),
      dataIndex: 'email',
      width: '25%',
      ellipsis: {
        showTitle: false
      },
      render: (_, record) => (
        <Tooltip placement="top" title={record?.email}>
          <Text className="text-medium-big ellipsis-cell-text">
            {getCurrentEmployeePosition(record?.email)}
          </Text>
        </Tooltip>
      )
    },

    ['Admin', 'HR'].includes(role) && {
      title: getTitleColumns('dateAssigned', 'members'),
      dataIndex: 'dateAssigned',
      className: `${allowPermision(role, rolesAllow) ? 'hoverEdit' : ''}`,
      render: (_, record) => (
        <>
          {dayjs(record.dateAssigned).isValid()
            ? dayjs(record.dateAssigned).format('DD-MM-YYYY')
            : '-'}
          <div
            className={allowPermision(role, adminRoles) ? 'showEdit' : ''}
            style={{ display: 'none' }}
          >
            <div className="actionButtons">
              <ButtonWithExpandedText
                id={record?.employeeId}
                displayedText={<FormattedMessage id="remove_btn_text" />}
                embedSrc={<ThemedIcon icon={UserProfileMinus} />}
                onClick={e => {
                  stateActions?.setRemoveModalSuccessMessage(
                    getSuccessMessages.success_member_removed
                  );
                  stateActions?.setRemoveModalTitle(
                    <FormattedMessage id="RemoveMemberModalTitle" />
                  );

                  const parameters = {
                    employeeId: record?.employeeId,
                    teamId: record?.teamId
                  };

                  const removeMember = () =>
                    TeamsService.removeMember(null, parameters);

                  const memberId = parseInt(e.currentTarget.id);

                  stateActions?.toggleModal();
                  stateActions?.setConfirmModalContent(
                    <FormattedMessage id="RemoveMemberConfirmMessage" />,
                    memberId &&
                      params.details.data.leadEmployeeId &&
                      memberId === params.details.data.leadEmployeeId ? (
                      <FormattedMessage id="MemberIsTLMessage" />
                    ) : (
                      ''
                    )
                  );

                  stateActions?.setOnOkModalPromise(() => removeMember);
                  stateActions.setCheckTeamLead(
                    memberId &&
                      params.details.data.leadEmployeeId &&
                      memberId === params.details.data.leadEmployeeId
                      ? true
                      : false
                  );
                }}
              />
            </div>
          </div>
        </>
      )
    }
  ];
  const dataSourceKeys = dataSource.length && dataSource[0];

  let keysDataSource = Object.keys(dataSourceKeys).filter(key =>
    titlesKeysofColumnsMembers.some(item => key === item.dataIndex)
  );

  const checkForDataDisplay = (column: any) =>
    keysDataSource.includes(column.dataIndex);

  return params.isAdminUserRole
    ? [...tableColumns.filter(checkForDataDisplay)]
    : tableColumns.filter(checkForDataDisplay);
};

function ViewTeam() {
  const { user } = useAuth();
  let [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const role = getCurrentUserRole(user);

  const dispatch = useDispatch();
  const [confirmModalContent, setConfirmModalContent] =
    useState<React.ReactNode>(null);
  const teamId =
    searchParams.get('teamId') || (localStorage.getItem('team-id') ?? 0);
  const { toggleModal, setOnOkModalPromise } = useConfirmationModalContext();

  const parameters = {
    teamId: +teamId,
    extended: 'true'
  };

  const { theme } = useThemeContext();
  const {
    isModalOpen,
    setIsModalOpen,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen,
    setViewRequestModal,
    viewRequestModal
  } = useModalContext();
  const [editable, setEditable] = useState(false);
  const [checkTeamLead, setCheckTeamLead] = useState(false);
  const [checkIfAlreadyInEdit, setCheckIfAlreadyInEdit] = useState(true);
  const [removeModalTitle, setRemoveModalTitle] = useState(
    <FormattedMessage id="RemoveMemberModalTitle" />
  );
  const [removeModalSuccessMessage, setRemoveModalSuccessMessage] = useState(
    getSuccessMessages.success_member_removed
  );
  const { data, status } = useApiRequestHook<ITeam>(
    TeamsService.getTeam,
    null,
    {},
    parameters
  );

  const details = { data, status };

  const onCancelEditDetails = () => {
    TeamsService.getTeam(null, parameters).then(() =>
      dispatch(setMethodUpdate(true))
    );
    setCheckIfAlreadyInEdit(true);
    setEditable(false);
    form.resetFields();
  };

  const saveUpdates = () => {
    form.submit();
    setEditable(false);
    setCheckIfAlreadyInEdit(true);
  };
  const isAdminUserRole = allowPermision(user.role, adminRoles);
  const params = {
    theme,
    details,
    isAdminUserRole
  };

  const [isAddMemberModal, setIsAddMemberModal] = useState(false);
  const [isAddContactModal, setIsAddContactModal] = useState(false);

  const [memberModalProps, setMemberModalProps] = useState<any>({});

  interface ButtonsType {
    show?: boolean;
    className?: string;
    type?: string;
    icon?: JSX.Element;
    iconSrc?: any;
    text: ReactNode;
    action?: any;
    isUploadButton?: boolean;
    isMultipleUpload?: boolean;
    uploadAccept?: string;
    uploadMaxCount?: number;
    uploadInProgress?: boolean;
    uploadShowList?: boolean;
    beforeUploadAction?: any;
    isDropdown?: boolean;
    dropdownOverlay?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >;
  }

  const ButtonComponent = BaseButton(ButtonSimple);
  const {
    setDisplayMembers,
    displayMembers,
    setDisplayContacts,
    displayContacts
  } = useTeamViewContext();
  const [contactFormData, setContactFormData] = useState(null);
  const [contactModalScope, setContactModalScope] = useState('create');
  const { isMobile, deviceHeight } = useMobileContext();
  const isTeamLead =
    allowPermision(role, userRoles.TL) &&
    user.employeeId === details.data?.leadEmployeeId;

  const topRoleButtons: ButtonsType[] = [
    {
      icon: <AddUserSvg />,
      text: (
        <span>
          <FormattedMessage id="add" />{' '}
        </span>
      ),
      action: () => {
        setIsAddContactModal(true);
        setIsTopRoleModalOpen(false);
        setIsModalOpen(true);
      },
      show: allowPermision(role, adminRoles) || checkTeamLead
    },
    {
      icon: <AddUserSvg />,
      text: (
        <span>
          <FormattedMessage id="AddMember" />{' '}
        </span>
      ),
      action: () => {
        setIsAddMemberModal(true);
        setIsTopRoleModalOpen(false);
        setIsModalOpen(true);
      },
      show: allowPermision(role, adminRoles)
    },
    {
      icon: <EditIconSimpleSvg />,
      text: (
        <span>
          <FormattedMessage id="EditTeam" />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
        setDisplayMembers(false);
        setDisplayContacts(false);
        setEditable(true);
        setCheckIfAlreadyInEdit(false);
      },
      show: allowPermision(role, adminRoles) && checkIfAlreadyInEdit
    },
    {
      icon: <></>,
      text: (
        <span>
          <FormattedMessage id={'team_image'} />
        </span>
      ),
      action: () => {
        setIsTopRoleModalOpen(false);
        setIsModalOpen(true);
      },
      show:
        (allowPermision(role, [userRoles.HR, userRoles.Admin]) || isTeamLead) &&
        isMobile
    }
  ];

  const tabItems = [
    {
      label: `Members`,
      key: 'members',
      children: (
        <TableComponent
          scroll={{
            x: 'calc(100vw - 215px)',
            y: 'calc(100vh - 531px)'
          }}
          tableSize="large"
          style={{
            height: 'calc(100vh - 38.4em)',
            minHeight: 'calc(100vh - 38.4em)'
          }}
          baseTitle="Team members"
          rowKey="employeeId"
          rowClassName={'hoverRow'}
          dataSource={data?.members}
          data={data?.members}
          params={params}
          columns={columns}
          hookUseStateActions={{
            toggleModal,
            setConfirmModalContent,
            setOnOkModalPromise,
            setCheckTeamLead,
            setRemoveModalTitle,
            setRemoveModalSuccessMessage
          }}
          role={role}
          className="viewTeamTable"
        />
      )
    },
    {
      label: `Contacts`,
      key: 'contacts',
      children: (
        <TableComponent
          scroll={{
            x: 'calc(100vw - 215px)',
            y: 'calc(100vh - 531px)'
          }}
          tableSize="large"
          style={{
            height: 'calc(100vh - 38.4em)',
            minHeight: 'calc(100vh - 38.4em)'
          }}
          baseTitle="Team members"
          rowKey="employeeId"
          rowClassName={'hoverRow'}
          dataSource={data?.contacts}
          data={data?.contacts}
          params={params}
          columns={useContactColumns}
          hookUseStateActions={{
            toggleModal,
            setConfirmModalContent,
            setOnOkModalPromise,
            setIsAddContactModal,
            setIsTopRoleModalOpen,
            setIsModalOpen,
            setContactFormData,
            setContactModalScope,
            setRemoveModalTitle,
            setRemoveModalSuccessMessage
          }}
          role={role}
          className="viewTeamTable"
        />
      )
    }
  ];

  const scrollY = `${deviceHeight - 335}px`;

  const [isRemove, setIsRemove] = useState<boolean>(false);

  const handleRemoveUser = () => {
    setRemoveModalTitle(<FormattedMessage id="RemoveMemberModalTitle" />);
    setRemoveModalSuccessMessage(getSuccessMessages.success_member_removed);
    setIsRemove(false);
    toggleModal();
  };

  const intl = useIntl();
  return (
    <ComponentWithStatus
      status={status}
      Placeholder={
        isMobile ? <MobileEditTeamPlaceholder /> : <EditTeamFormPlaceholder />
      }
    >
      <TeamDetails
        setIsAddMemberModal={setIsAddMemberModal}
        teamId={teamId}
        edit={editable}
        role={role}
        details={[details.data]}
        form={form}
        onSaveDetails={saveUpdates}
        onCancelEditDetails={onCancelEditDetails}
        setEditable={setEditable}
        checkTeamLead={checkTeamLead}
        setCheckTeamLead={setCheckTeamLead}
        setCheckIfAlreadyInEdit={setCheckIfAlreadyInEdit}
        setIsAddContactModal={setIsAddContactModal}
      />

      {isMobile ? (
        <>
          {(displayMembers || displayContacts) && (
            <TableLayoutPage
              title={<FormattedMessage id="members" />}
              displayToggle={false}
              style={{
                marginTop: '16px',
                position: 'relative',
                zIndex: '1',
                height: `calc(${deviceHeight}px - 201px)`
              }}
            >
              <ComponentWithStatus
                status={data ? 'success' : 'pending'}
                Placeholder={<TableComponentPlaceholder count={2} />}
              >
                {displayMembers ? (
                  <TableComponent
                    scroll={{
                      x: 'calc(100vw - 213px)',
                      y: `calc(${deviceHeight}px - 28em)`
                    }}
                    style={{
                      minHeight: `calc(${deviceHeight}px - 24em)`
                    }}
                    baseTitle="Team members"
                    rowKey="employeeId"
                    dataSource={data?.members}
                    data={data?.members}
                    params={params}
                    columns={columnsMobileTeamView}
                    hookUseStateActions={{
                      toggleModal,
                      setConfirmModalContent,
                      setOnOkModalPromise,
                      setCheckTeamLead
                    }}
                    className="viewTeamTable"
                    role={role}
                    onRow={record => {
                      return {
                        onClick: e => {
                          setConfirmModalContent(
                            <FormattedMessage id="RemoveContactConfirmMessage" />
                          );
                          setMemberModalProps({ ...record });
                          setViewRequestModal(true);

                          const parameters = {
                            employeeId: record?.employeeId,
                            teamId: record?.teamId
                          };

                          const removeMember = () =>
                            TeamsService.removeMember(null, parameters);

                          const memberId = parseInt(e.currentTarget.id);
                          setConfirmModalContent(
                            intl.formatMessage({
                              id: 'RemoveMemberConfirmMessage'
                            }) +
                              (memberId &&
                              params.details.data.leadEmployeeId &&
                              memberId === params.details.data.leadEmployeeId
                                ? intl.formatMessage({
                                    id: 'MemberIsTLMessage'
                                  })
                                : '')
                          );

                          setOnOkModalPromise(() => removeMember);
                          setCheckTeamLead(
                            memberId &&
                              params.details.data.leadEmployeeId &&
                              memberId === params.details.data.leadEmployeeId
                              ? true
                              : false
                          );
                        }
                      };
                    }}
                  />
                ) : (
                  <TableComponent
                    scroll={{
                      x: 'calc(100vw - 213px)',
                      y: `calc(${deviceHeight}px - 28em)`
                    }}
                    style={{
                      minHeight: `calc(${deviceHeight}px - 24em)`
                    }}
                    baseTitle="Team members"
                    rowKey="employeeId"
                    rowClassName={'hoverRow'}
                    dataSource={data?.contacts}
                    data={data?.contacts}
                    params={params}
                    columns={useContactColumns}
                    hookUseStateActions={{
                      toggleModal,
                      setConfirmModalContent,
                      setOnOkModalPromise,
                      setIsAddContactModal,
                      setIsTopRoleModalOpen,
                      setIsModalOpen,
                      setContactFormData,
                      setContactModalScope
                    }}
                    role={role}
                    className="viewTeamTable"
                    onRow={record => {
                      return {
                        onClick: e => {
                          setIsAddContactModal(true);
                          setContactFormData(record);
                          setContactModalScope('edit');
                          setIsModalOpen(true);
                        }
                      };
                    }}
                  />
                )}
              </ComponentWithStatus>
            </TableLayoutPage>
          )}
        </>
      ) : (
        <>
          <TableLayoutPage
            displayToggle={false}
            style={{ marginTop: '16px', position: 'relative' }}
          >
            <Tabs type="card" items={tabItems} />
          </TableLayoutPage>
        </>
      )}

      {isAddMemberModal && (
        <ModalViewEmployees
          members={data?.members}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setIsAddMemberModal={setIsAddMemberModal}
          teamId={teamId}
        />
      )}

      {isAddContactModal && (
        <ModalAddContact
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setIsAddMemberModal={setIsAddContactModal}
          teamId={teamId}
          defaultData={contactFormData}
          setDefaultData={setContactFormData}
          scope={contactModalScope}
          setScope={setContactModalScope}
          setConfirmModalContent={setConfirmModalContent}
          setOnOkModalPromise={setOnOkModalPromise}
          setRemoveModalTitle={setRemoveModalTitle}
        />
      )}

      {/* user view more details about memebers in team */}
      <ModalComponent
        title={<FormattedMessage id="ViewMemberModalTitle" />}
        isOpen={viewRequestModal}
        setIsOpen={setViewRequestModal}
        formKeyId="viewMemberForm"
        children={<ViewMemberForm modalProps={{ ...memberModalProps }} />}
        displayFooterCloseButton={true}
        displayFooterSubmitButton={isAdminUserRole}
        onSubmit={handleRemoveUser}
        cancelBtnText={<FormattedMessage id="close" />}
        submitBtnText={<FormattedMessage id="remove" />}
      />

      {isMobile && !isAddMemberModal && !isAddContactModal && (
        <ViewTeamImage
          teamImageSrc={details.data?.teamImageUrl}
          isModalOpen={isModalOpen}
          teamName={details.data?.name}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <ConfirmModalComponent
        className="remove-member-modal"
        title={removeModalTitle}
        setMessageSuccessfully={removeModalSuccessMessage}
        okText={intl.formatMessage({ id: 'remove_btn_text' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        confirmContent={confirmModalContent}
      />
      <ModalComponent
        displayFooter={false}
        className={`top-role-popup ${
          topRoleButtons.length === 1 ? 'top-role-popup-single' : ''
        }`}
        isOpen={isTopRoleModalOpen}
        setIsOpen={setIsTopRoleModalOpen}
        footerButtons={false}
        displayFooterCloseButton={false}
        displayFooterSubmitButton={false}
        maskClosable={true}
      >
        <div className="top-role-popup-buttons">
          {topRoleButtons.map((button, index) =>
            button.show ? (
              <ButtonComponent
                key={`button-component-${index}`}
                type={button.type}
                icon={
                  button.icon ? (
                    button.icon
                  ) : button.iconSrc ? (
                    <embed src={button.iconSrc} />
                  ) : null
                }
                className={
                  button.className
                    ? button.className
                    : `text-bold-normal btn-default-custom ${theme}`
                }
                style={
                  topRoleButtons.indexOf(button) === topRoleButtons.length - 1
                    ? { marginBottom: 0 }
                    : { margin: '0' }
                }
                onClick={button.action}
              >
                {button.text}
              </ButtonComponent>
            ) : null
          )}
        </div>
      </ModalComponent>
      <ModalComponent
        displayFooter={false}
        className={`top-role-popup ${
          topRoleButtons.length === 1 ? 'top-role-popup-single' : ''
        }`}
        isOpen={isRemove}
        setIsOpen={setIsRemove}
        footerButtons={false}
        displayFooterCloseButton={false}
        displayFooterSubmitButton={false}
        maskClosable={true}
      >
        <ButtonComponent
          className={`text-bold-normal btn-default-custom ${theme}`}
          onClick={handleRemoveUser}
        >
          <FormattedMessage id="remove" />
        </ButtonComponent>
      </ModalComponent>
    </ComponentWithStatus>
  );
}

export default ViewTeam;
