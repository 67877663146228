import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { IModalComponentProps } from 'components/Modal';
import ThemedIcon from 'components/UIComponents/Icon';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import _sort from 'utils/sorter';
import { TeamsService } from '../../../services';
import React from 'react';
import { getTitleColumns } from '../../../components/TitlesColumns/utils';
import TextPhoneInput from '../../../components/UIComponents/InputPhoneComponent/TextPhoneInput';

export const useContactColumns = (
  data = null,
  role,
  params,
  isModalOpen,
  setIsOpen,
  stateActions,
  dataSource
): ColumnsType<any> => {
  const { isMobile, isTabletDevice } = useMobileContext();
  const intl = useIntl();

  //------End Row Actions

  const tableColumns = [
    {
      title: <FormattedMessage id="Name" />,
      dataIndex: 'name',
      sorter: (a, b) => _sort(a.name, b.name, 'string'),
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p>{record.name}</p>
    },
    !isMobile && {
      title: <FormattedMessage id="Function" />,
      dataIndex: 'function',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p>{record?.contactFunction}</p>
    },
    {
      title: <FormattedMessage id="Company" />,
      dataIndex: 'company',
      width: isTabletDevice || isMobile ? '8%' : '35%',
      render: (_, record) => <p>{record?.company}</p>
    },
    {
      title: <FormattedMessage id="PhoneNumber" />,
      dataIndex: 'phoneNumber',
      width: isMobile ? '15%' : '25%',
      render: (_, record) => (
        <Tooltip
          placement="top"
          title={<TextPhoneInput crudPhone={record.phoneNumber} />}
        >
          <span className="line-clamp-1">
            <TextPhoneInput crudPhone={record.phoneNumber} />
          </span>
        </Tooltip>
      )
    },
    !isMobile && {
      title: <FormattedMessage id="Email" />,
      dataIndex: 'email',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      render: (_, record) => <p>{record?.email}</p>
    },
    !isMobile && {
      title: <FormattedMessage id="Notes" />,
      dataIndex: 'notes',
      width: isTabletDevice || isMobile ? '10%' : '35%',
      className: 'hoverEdit',
      render: (_, record) => (
        <>
          <p>{record?.notes ? record?.notes : '-'}</p>
          <div className={'showEdit'} style={{ display: 'none' }}>
            <div className="actionButtons">
              <ButtonWithExpandedText
                displayedText={<FormattedMessage id="edit" />}
                embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                key="edit-category-btn"
                type="link"
                onClick={e => {
                  stateActions?.setContactModalScope('edit');
                  stateActions?.setContactFormData(record);
                  stateActions?.setIsAddContactModal(true);
                  stateActions?.setIsTopRoleModalOpen(false);
                  stateActions?.setIsModalOpen(true);
                }}
              />
              <ButtonWithExpandedText
                embedSrc={<ThemedIcon icon={TimeOffDeleteIcon} />}
                displayedText={<FormattedMessage id="remove" />}
                type="link"
                onClick={e => {
                  stateActions.setRemoveModalTitle(
                    <FormattedMessage id="RemoveContactModalTitle" />
                  );

                  const parameters = {
                    id: record?.id
                  };

                  const removeContact = () =>
                    TeamsService.deleteTeamContact(null, parameters);

                  stateActions?.toggleModal();
                  stateActions?.setConfirmModalContent(
                    <FormattedMessage id="RemoveContactConfirmMessage" />
                  );

                  stateActions?.setOnOkModalPromise(() => removeContact);
                }}
              />
            </div>
          </div>
        </>
      )
    }
  ].filter(Boolean);

  return [...tableColumns];
};
