import ModalComponent from '../../../components/Modal';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Select, Switch } from 'antd';
import { TableLayoutPage } from '../../../components/LayoutPageWithTitle.tsx';
import { useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import useApiRequestHook from '../../../hooks/useApiRequest.hook';
import { CompanySettingsService } from '../../../services';

import './index.less';
import dayjs from 'dayjs';

import SearchToolbarComponent from '../../../components/UIComponents/SearchToolbar';
import { useModalContext } from '../../../components/Modal/ModalProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import DropdownInputPhone from '../../../components/UIComponents/InputPhoneComponent/DropdownInputPhone';
import { JsonEditor } from './JsonEditor';
import { SelectDropdown } from '../../../components/Dropdown';
import { ColFormItemComponentWithTooltip } from './ColFormItemComponentWithTooltip';
import TimeRangePicker from '../../../components/TimeRangePicker';
import { onFormFinish } from './utils';
import ComponentWithStatus from '../../../components/ComponentWithStatus';
import { PlatformSettingsPlaceHolder } from '../../../components/Placeholders/Form';
import { notifyWithSuccessfulDataUpdated } from '../../../utils/notificationsUtils';
import { getSuccessMessages } from '../../../utils/getSuccessMessages';

export const PlatformSettings = () => {
  const { theme } = useThemeContext();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [rawData, setRawData] = useState(null);
  const intl = useIntl();
  const [
    limitMaximumAmountOfPersonalLeave,
    setLimitMaximumAmountOfPersonalLeave
  ] = useState(false);
  const [
    limitMaximumAmountOfOffsetInAdvance,
    setLimitMaximumAmountOfOffsetInAdvance
  ] = useState(false);
  const [limitOffsetStartInterval, setLimitOffsetStartInterval] =
    useState(false);
  const [offsettingStructure, setOffsettingStructure] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const { toggleModal } = useModalContext();
  const generalRootState = useSelector((state: RootState) => state);
  const offsetingStructureoptions =
    generalRootState?.general?.enums?.OffsettingStructure?.filter(
      struct => struct !== 'None'
    );
  const countries = generalRootState?.general?.enums?.Countries;
  const timeZonesid = generalRootState?.general?.enums?.TimeZoneId;

  const [modalProps, setModalProps] = useState({
    key: null,
    modalTitle: null,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    displayFooterSubmitButton: true,
    displayFooterCloseButton: true,
    width: '500px',
    children: null,
    formKeyId: ''
  });

  const [platformSettings, setPlatfromSettings] = useState(null);
  const { data, status } = useApiRequestHook<any>(
    CompanySettingsService.getCompanySettingsExtended,
    null,
    [],
    {}
  );

  useEffect(() => {
    setPlatfromSettings(data);
    setLimitMaximumAmountOfPersonalLeave(
      !data?.appConfiguration?.limitMaximumAmountOfPersonalLeave
    );
    setLimitMaximumAmountOfOffsetInAdvance(
      !data?.appConfiguration?.limitMaximumAmountOfOffsetInAdvance
    );
    setLimitOffsetStartInterval(
      data?.appConfiguration?.limitOffsetStartInterval
    );
    setOffsettingStructure(data?.appConfiguration?.offsettingStructure);
  }, [data]);

  useEffect(() => {
    if (!data?.appConfiguration) return;

    const {
      appConfiguration: {
        allowSameTeamLeadForMultipleTeams,
        country,
        forbidOffsetForFutureHours,
        leaveOffsetCustomPeriodReminderDays,
        limitMaximumAmountOfOffsetInAdvance,
        limitMaximumAmountOfPersonalLeave,
        limitOffsetStartInterval,
        maxEventRecurrenceRangeInDays,
        maximumAmountOfOffsetInAdvanceHours,
        maximumAmountOfPersonalLeaveHours,
        minimumDurationOfPersonalLeaveInMinutes,
        offsetStartInterval,
        offsettingStructure,
        offsettingValue,
        reccuringJobsConfiguration,
        slaTimeFrameDueDateDays,
        slaTimeFrameRequesterDays,
        timeZoneId
      },
      countryCode,
      email,
      emailProvider,
      emailType,
      name,
      office,
      phoneCode,
      phoneCodeCount,
      phoneCodeCountry,
      phoneCodeDigit,
      phoneCount
    } = data;

    const formattedOffsetStartInterval = offsetStartInterval
      ? offsetStartInterval.split('-').map(time => dayjs(time, 'HH:mm'))
      : undefined;

    form.setFieldsValue({
      allowSameTeamLeadForMultipleTeams,
      country,
      forbidOffsetForFutureHours: !forbidOffsetForFutureHours,
      leaveOffsetCustomPeriodReminderDays,
      limitMaximumAmountOfOffsetInAdvance: !limitMaximumAmountOfOffsetInAdvance,
      limitMaximumAmountOfPersonalLeave: !limitMaximumAmountOfPersonalLeave,
      limitOffsetStartInterval,
      maxEventRecurrenceRangeInDays,
      maximumAmountOfOffsetInAdvanceHours,
      maximumAmountOfPersonalLeaveHours,
      minimumDurationOfPersonalLeaveInMinutes,
      offsetStartInterval: formattedOffsetStartInterval,
      offsettingStructure,
      offsettingValue,
      reccuringJobsConfiguration,
      slaTimeFrameDueDateDays,
      slaTimeFrameRequesterDays,
      timeZoneId,
      countryCode,
      email,
      emailProvider,
      emailType,
      name,
      office,
      phoneCode,
      phoneCodeCount,
      phoneCodeCountry,
      phoneCodeDigit,
      phoneCount
    });
  }, [data]);

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const handleOpenRawEdit = () => {
    setModalProps({
      key: 'JsonEdit',
      modalTitle: intl.formatMessage({ id: 'edit_config_modal_title' }),
      submitButtonText: <FormattedMessage id="submit" />,
      cancelButtonText: <FormattedMessage id="cancel" />,
      displayFooterSubmitButton: true,
      displayFooterCloseButton: true,
      width: '700px',
      children: <JsonEditor setRawData={setRawData} />,
      formKeyId: 'raw-edit'
    });
    toggleModal();
  };

  const handleSubmit = () => {
    rawData &&
      CompanySettingsService.updateCompanySettings(rawData).then(() => {
        toggleModal();
        notifyWithSuccessfulDataUpdated(
          '',
          getSuccessMessages.success_updated_platform_settings
        );
        setRawData(null);
      });
  };
  const handleOnBeforeInput = (
    event: React.SyntheticEvent<HTMLInputElement, InputEvent>
  ) => {
    if (isNaN(parseInt(event?.nativeEvent?.data))) event.preventDefault();
    return;
  };

  return (
    <div className={`platform-settings ${theme}`}>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        className="vacation_module_search_toolbar"
        buttons={[
          {
            icon: <EditOutlined />,
            text: <span>Raw edit</span>,
            action: handleOpenRawEdit,
            show: true
          },
          {
            icon: <SaveOutlined />,
            text: <span>{intl.formatMessage({ id: 'saveChanges' })}</span>,
            action: form.submit,
            show: true
          }
        ]}
      />
      <ModalComponent
        style={{ maxHeight: '100px' }}
        key={modalProps.key}
        title={modalProps.modalTitle}
        submitBtnText={modalProps.submitButtonText}
        displayFooterSubmitButton={modalProps?.displayFooterSubmitButton}
        cancelBtnText={modalProps.cancelButtonText}
        displayFooterCloseButton={modalProps?.displayFooterCloseButton}
        formKeyId={modalProps.formKeyId}
        width={modalProps.width}
        className="modal_without_effects_select"
        disableSubmitButton={!rawData}
        onSubmit={handleSubmit}
      >
        {modalProps.children}
      </ModalComponent>
      <Form form={form} onFinish={onFormFinish}>
        <ComponentWithStatus
          status={status}
          Placeholder={<PlatformSettingsPlaceHolder boxheight={'175px'} />}
        >
          <TableLayoutPage
            title={intl.formatMessage({ id: 'general_settings' })}
            className={'general_settings'}
          >
            <div className="form-block-layout">
              <div className="block-content-wrapper">
                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({ id: 'Time_zone_label' })}
                  name={'timeZoneId'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({ id: 'Time_zone_tooltip' })}
                >
                  <SelectDropdown placement_bottom={true}>
                    {timeZonesid?.map(tz => (
                      <Option key={tz} value={tz.replace('_', '/')}>
                        {intl.formatMessage({ id: tz })}
                      </Option>
                    ))}
                  </SelectDropdown>
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({ id: 'Phone_code_label' })}
                  name={'phoneCode'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({ id: 'Phone_code_tooltip' })}
                >
                  <DropdownInputPhone
                    disablePhoneInput={true}
                    value={
                      platformSettings?.appSettings?.phoneCode &&
                      platformSettings?.appSettings?.phoneCode
                    }
                    onChange={setPhoneCode}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Allow_TL_for_multiple_teams_label'
                  })}
                  name={'allowSameTeamLeadForMultipleTeams'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Allow_TL_for_multiple_teams_tooltip'
                  })}
                  valuePropName={'checked'}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({ id: 'Office_label' })}
                  name={'office'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({ id: 'Office_tooltip' })}
                >
                  <Input disabled={false} />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({ id: 'Country_label' })}
                  name={'country'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({ id: 'Country_tooltip' })}
                >
                  <SelectDropdown placement_bottom={true}>
                    {countries?.map(country => (
                      <Option key={country}>{country}</Option>
                    ))}
                  </SelectDropdown>
                </ColFormItemComponentWithTooltip>
              </div>
            </div>
          </TableLayoutPage>
        </ComponentWithStatus>

        <ComponentWithStatus
          status={status}
          Placeholder={<PlatformSettingsPlaceHolder boxheight={'269px'} />}
        >
          <TableLayoutPage
            title={intl.formatMessage({ id: 'Time_management_settings' })}
            className={'Time_management_settings'}
          >
            <div className="form-block-layout">
              <div className="block-content-wrapper">
                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Limit_maximum_amount_of_personal_leave_label'
                  })}
                  name={'limitMaximumAmountOfPersonalLeave'}
                  className={`col-form-item-component ${theme} `}
                  valuePropName={'checked'}
                  tooltip={intl.formatMessage({
                    id: 'Limit_maximum_amount_of_personal_leave_tooltip'
                  })}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={value =>
                      setLimitMaximumAmountOfPersonalLeave(value)
                    }
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Maximum_amount_of_personal_leave_hours_label'
                  })}
                  name={'maximumAmountOfPersonalLeaveHours'}
                  className={`col-form-item-component ${theme} ${limitMaximumAmountOfPersonalLeave ? 'disabled' : ''}`}
                  tooltip={intl.formatMessage({
                    id: 'Maximum_amount_of_personal_leave_hours_tooltip'
                  })}
                >
                  <Input
                    disabled={limitMaximumAmountOfPersonalLeave}
                    onBeforeInput={handleOnBeforeInput}
                    type={'number'}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Minimum_duration_of_personal_leave_in_minutes_label'
                  })}
                  name={'minimumDurationOfPersonalLeaveInMinutes'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Minimum_duration_of_personal_leave_in_minutes_tooltip'
                  })}
                >
                  <Input onBeforeInput={handleOnBeforeInput} type={'number'} />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Forbid_offset_for_future_hours_label'
                  })}
                  name={'forbidOffsetForFutureHours'}
                  className={`col-form-item-component ${theme} `}
                  valuePropName={'checked'}
                  tooltip={intl.formatMessage({
                    id: 'Forbid_offset_for_future_hours_tooltip'
                  })}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Limit_maximum_amount_of_offset_in_advance_label'
                  })}
                  name={'limitMaximumAmountOfOffsetInAdvance'}
                  className={`col-form-item-component ${theme} `}
                  valuePropName={'checked'}
                  tooltip={intl.formatMessage({
                    id: 'Limit_maximum_amount_of_offset_in_advance_tooltip'
                  })}
                >
                  <Switch
                    onChange={value =>
                      setLimitMaximumAmountOfOffsetInAdvance(value)
                    }
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Maximum_amount_of_offset_in_advance_hours_label'
                  })}
                  name={'maximumAmountOfOffsetInAdvanceHours'}
                  className={`col-form-item-component ${theme} ${limitMaximumAmountOfOffsetInAdvance ? 'disabled' : ''}`}
                  tooltip={intl.formatMessage({
                    id: 'Maximum_amount_of_offset_in_advance_hours_tooltip'
                  })}
                >
                  <Input
                    disabled={limitMaximumAmountOfOffsetInAdvance}
                    onBeforeInput={handleOnBeforeInput}
                    type={'number'}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Limit_offset_start_interval_label'
                  })}
                  name={'limitOffsetStartInterval'}
                  className={`col-form-item-component ${theme} `}
                  valuePropName={'checked'}
                  tooltip={intl.formatMessage({
                    id: 'Limit_offset_start_interval_tooltip'
                  })}
                >
                  <Switch
                    onChange={value => setLimitOffsetStartInterval(value)}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Offset_start_interval_label'
                  })}
                  name={'offsetStartInterval'}
                  className={`col-form-item-component ${theme} ${!limitOffsetStartInterval ? 'disabled' : ''}`}
                  tooltip={intl.formatMessage({
                    id: 'Offset_start_interval_tooltip'
                  })}
                >
                  <TimeRangePicker
                    disabled={!limitOffsetStartInterval}
                    popupClassName={`time-range-picker ${theme}`}
                    format={'HH:mm'}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Leave_offset_custom_period_reminder_days_label'
                  })}
                  name={'leaveOffsetCustomPeriodReminderDays'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Leave_offset_custom_period_reminder_days_tooltip'
                  })}
                >
                  <Input
                    type={'number'}
                    onBeforeInput={handleOnBeforeInput}
                    disabled={false}
                    minLength={1}
                    maxLength={26}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Offsetting_structure_label'
                  })}
                  name={'offsettingStructure'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Offsetting_structure_tooltip'
                  })}
                >
                  <SelectDropdown
                    placement_bottom={true}
                    onChange={val => setOffsettingStructure(val)}
                  >
                    {offsetingStructureoptions?.map(structure => (
                      <Option key={structure} value={structure}>
                        {intl.formatMessage({ id: structure })}
                      </Option>
                    ))}
                  </SelectDropdown>
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({ id: 'Offsetting_value_label' })}
                  name={'offsettingValue'}
                  className={`col-form-item-component ${theme} ${offsettingStructure === 'Unlimited' || offsettingStructure === 'LastDayOfMonth' || offsettingStructure === 'None' ? 'disabled' : ''}`}
                  tooltip={intl.formatMessage({
                    id: 'Offsetting_value_tooltip'
                  })}
                >
                  <Input
                    disabled={
                      offsettingStructure === 'Unlimited' ||
                      offsettingStructure === 'LastDayOfMonth' ||
                      offsettingStructure === 'None'
                    }
                    onBeforeInput={handleOnBeforeInput}
                    type={'number'}
                  />
                </ColFormItemComponentWithTooltip>
              </div>
            </div>
          </TableLayoutPage>
        </ComponentWithStatus>

        <ComponentWithStatus
          status={status}
          Placeholder={<PlatformSettingsPlaceHolder boxheight={'126px'} />}
        >
          <TableLayoutPage
            title={intl.formatMessage({ id: 'Ticketing_settings' })}
          >
            <div className="form-block-layout">
              <div className="block-content-wrapper">
                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Sla_time_frame_due_date_days_label'
                  })}
                  name={'slaTimeFrameRequesterDays'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Sla_time_frame_due_date_days_tooltip'
                  })}
                >
                  <Input
                    disabled={false}
                    type={'number'}
                    onBeforeInput={handleOnBeforeInput}
                  />
                </ColFormItemComponentWithTooltip>

                <ColFormItemComponentWithTooltip
                  label={intl.formatMessage({
                    id: 'Sla_time_frame_requester_days_label'
                  })}
                  name={'slaTimeFrameDueDateDays'}
                  className={`col-form-item-component ${theme} `}
                  tooltip={intl.formatMessage({
                    id: 'Sla_time_frame_requester_days_tooltip'
                  })}
                >
                  <Input
                    disabled={false}
                    type={'number'}
                    onBeforeInput={handleOnBeforeInput}
                  />
                </ColFormItemComponentWithTooltip>
              </div>
            </div>
          </TableLayoutPage>
        </ComponentWithStatus>
      </Form>
    </div>
  );
};
