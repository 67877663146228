import { Col, Typography } from 'antd';
import { useModalContext } from 'components/Modal/ModalProvider';
import { FC, useEffect, useRef, useState } from 'react';
import { EmployeeDocumentAssets } from 'services/providers/EmployeeDocumentProvider/utils';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import EmployeeDocumentsList from 'components/Employee/EmployeeDocumentsList';
import ModalComponent from 'components/Modal';
import SplideCustomCarousel from 'components/SplideCarousel';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { setDocumentsFormView } from 'redux/slices/documentsSlice';
import { RootState, useAppDispatch } from 'redux/store';
import DocumentsModalHeader from './DocumentsModalHeader';
import './documentItemStyle.less';
import EmployeeDocumentsForm from './DocumentsForm';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { ArrowLeftRounded } from '../../../../../Icons/TimeManagementWidget/arrowLeftRounded';
type DocumentItemsProps = {
  activeIdx?: any;
  setModalOpen?: any;
  setModalProps?: any;
  requestFormProps?: any;
  size?: any;
  openModal?: any;
  className?: any;
  minHeight?: any;
  widthTrackingCarouselRequest?: any;
  scope?: string;
  setActiveIdx?: any;
  employeeId?: any;
};

export const DocumentItems: FC<DocumentItemsProps> = ({
  setModalOpen,
  openModal,
  employeeId
}) => {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const { Text } = Typography;
  const { toggleModal, isModalOpen, setIsModalOpen } = useModalContext();
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<number>(0);
  const carouselItemHeight = 60;
  const { isMobile, deviceWidth } = useMobileContext();
  const carouselItemWidth = isMobile ? 230 : 170;

  const parentRef = useRef(null);
  const dispatch = useAppDispatch();
  const documentFormScope = useSelector(
    (state: RootState) => state.documents.documentsModalScope
  );
  const editDocumentId = useSelector(
    (state: RootState) => state.documents.editDocumentId
  );

  const setDocumentFormScope = (scope: 'view' | 'create' | 'edit') => {
    dispatch(setDocumentsFormView(scope));
  };

  useEffect(() => {
    //revert to default view state on unmount
    return () => setDocumentFormScope('view');
  }, [selectedDocumentIndex, openModal.viewDocuments]);

  return (
    <div ref={parentRef} style={{ width: '100%', margin: '0 auto' }}>
      {openModal.viewDocuments && (
        <ModalComponent
          isMaskClosable={false}
          key="documentsFormModal"
          formKeyId="documentsForm"
          width={
            documentFormScope === 'edit' || documentFormScope === 'create'
              ? isMobile
                ? deviceWidth - 32 + 'px'
                : 'auto'
              : '600px'
          }
          className="mobile-modal documents-modal"
          alignSelf={'top'}
          submitBtnText={
            documentFormScope === 'edit' || documentFormScope === 'create' ? (
              <FormattedMessage id="save" />
            ) : (
              <FormattedMessage id="create" />
            )
          }
          cancelBtnText={
            documentFormScope === 'edit' || documentFormScope === 'create' ? (
              <FormattedMessage id="cancel" />
            ) : (
              <FormattedMessage id="close" />
            )
          }
          title={
            documentFormScope === 'view' ? (
              <DocumentsModalHeader
                documentType={
                  EmployeeDocumentAssets &&
                  EmployeeDocumentAssets[selectedDocumentIndex][0]
                }
                getNextDocumentType={() =>
                  setSelectedDocumentIndex(lastSelectedIndex =>
                    !EmployeeDocumentAssets[selectedDocumentIndex + 1]
                      ? 0
                      : lastSelectedIndex + 1
                  )
                }
                getPrevDocumentType={() =>
                  setSelectedDocumentIndex(lastSelectedIndex =>
                    !EmployeeDocumentAssets[selectedDocumentIndex - 1]
                      ? EmployeeDocumentAssets.length - 1
                      : lastSelectedIndex - 1
                  )
                }
              />
            ) : (
              <>
                <span>
                  {documentFormScope === 'edit' ? (
                    <FormattedMessage id="EditDocument" />
                  ) : (
                    <FormattedMessage id="CreateDocument" />
                  )}
                </span>
                <ArrowLeftRounded
                  style={{
                    position: 'absolute',
                    left: '24px',
                    fontSize: '2.5rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setDocumentFormScope('view');
                  }}
                />
              </>
            )
          }
          onSubmit={
            //when clicking the submit button of the parent modal,
            documentFormScope === 'view'
              ? () => setDocumentFormScope('create')
              : () => null
          }
          onCancelPressed={() => setDocumentFormScope('view')}
          children={
            <ComponentWithStatus
              status={
                employeeId && EmployeeDocumentAssets ? 'success' : 'pending'
              }
            >
              {documentFormScope === 'view' ? (
                <EmployeeDocumentsList
                  employeeId={employeeId}
                  documentType={
                    EmployeeDocumentAssets &&
                    EmployeeDocumentAssets[selectedDocumentIndex][0]
                  }
                />
              ) : documentFormScope === 'edit' ? (
                <EmployeeDocumentsForm
                  employeeId={employeeId}
                  editDocumentId={editDocumentId}
                  onSubmit={() => {
                    setDocumentFormScope('view');
                    setIsModalOpen(false);
                  }}
                />
              ) : documentFormScope === 'create' ? (
                <EmployeeDocumentsForm
                  employeeId={employeeId}
                  documentType={
                    EmployeeDocumentAssets[selectedDocumentIndex][0]
                  }
                  onSubmit={() => {
                    setDocumentFormScope('view');
                    setIsModalOpen(false);
                  }}
                />
              ) : null}
            </ComponentWithStatus>
          }
          // formKeyId="employeeForm"
        />
      )}
      <SplideCustomCarousel
        options={{
          easing: 'ease-in-out',
          speed: 800,
          perPage: 2,
          drag: false
        }}
      >
        {EmployeeDocumentAssets?.map(([title, icon], index) => {
          return (
            title !== 'None' && (
              <Col
                key={index}
                className={`align-documents documents-item slider ${
                  isModalOpen &&
                  selectedItem >= 0 &&
                  openModal?.request &&
                  index === selectedItem
                    ? 'selected'
                    : ''
                } `}
                data-item={index}
                style={{
                  // width: carouselItemWidth,
                  height: carouselItemHeight,
                  margin: '0'
                }}
                onClick={() => {
                  setSelectedItem(index);
                  setModalOpen({
                    editProfile: false,
                    clockInclockOut: false,
                    request: false,
                    viewDocuments: true
                  });
                  setSelectedDocumentIndex(index);
                  toggleModal();
                }}
              >
                <ReactSVG src={icon} />
                <span className="wrapper-bg">
                  <div className="content-title">
                    <Text className={`text-bold-normal content `}>
                      <FormattedMessage id={title} />
                    </Text>
                  </div>
                </span>
              </Col>
            )
          );
        })}
      </SplideCustomCarousel>
    </div>
  );
};
