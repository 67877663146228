import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import ModalComponent from 'components/Modal';
import { TableParams } from 'components/Table';
import TimeOffRequestForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm';
import TimeOffRequestTable from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable';
import StateActionsForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable/StateActionsForm';
import { TimeOffColleaguesActions } from 'context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import {
  defaultState as dS,
  initialState,
  timeOffColleaguesRequestsStateKey
} from 'context/TimeManagement/ColleaguesRequests/reducers';
import { TimeOffRequestsAndResponse } from 'context/TimeManagement/types';
import { loadState } from 'context/utils';
import React, { useEffect } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { TimeOffService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow,
  userRoles
} from 'utils/userManagement';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import { useCompanySettings } from '../../../settings';
import ColleaguesTimeOffToolbar from './ColleaguesTimeOffToolbar';
import { colleagueRequestModalProps } from './types';

import './index.less';

const ColleaguesTimeOffPage = () => {
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isTeamLeaderRole = userRole === userRoles.TL;
  let [searchParams, setSearchParams] = useSearchParams();
  const { countryCode } = useCompanySettings();
  // By default filters
  const defaultState =
    dS[
      allowPermision(userRole, rolesAllow) || isTeamLeaderRole
        ? 'admin'
        : 'employee'
    ];

  const { timeOffColleaguesState, timeOffColleaguesDispatch } =
    useColleaguesRequestsFilterContext();
  const currentState = loadState(timeOffColleaguesRequestsStateKey);
  const serializedState: TimeOffRequestsAndResponse = currentState
    ? JSON.parse(currentState)
    : {};

  const payload = {
    filterBy: {
      customStatuses: searchParams.has('customStatuses')
        ? [searchParams.get('customStatuses')]
        : defaultState?.filterBy?.customStatuses,
      policies: searchParams.has('policies')
        ? [searchParams.get('policies')]
        : initialState?.filterBy?.policies,
      period: searchParams.has('startDate')
        ? {
            customTimeRangeCriteria: 'OnlyStartsInInterval',
            startDate: searchParams.get('startDate'),
            endDate: searchParams.get('endDate'),
            type: 'TimeRange'
          }
        : initialState?.filterBy?.period
    },
    filters: {
      customStatuses: searchParams.has('customStatuses')
        ? [searchParams.get('customStatuses')]
        : defaultState?.filters?.customStatuses,
      policies: searchParams.has('policies')
        ? searchParams.get('policies') === 'SelectedAllTypes'
          ? []
          : searchParams.get('policies')
        : initialState?.filters?.policies,
      period: searchParams.has('startDate')
        ? {
            customTimeRangeCriteria: 'OnlyStartsInInterval',
            startDate: searchParams.get('startDate'),
            endDate: searchParams.get('endDate'),
            type: 'TimeRange'
          }
        : initialState?.filters?.period
    },
    pagination: {
      resultSize:
        serializedState?.pagination?.resultSize ??
        initialState?.pagination?.resultSize,
      pageNumber: initialState?.pagination?.pageNumber
    },
    requestId:
      parseInt(searchParams.get('requestId')) ??
      timeOffColleaguesState?.requestId
  };

  React.useEffect(() => {
    if (window.location.search || payload?.requestId) {
      timeOffColleaguesDispatch({
        type: TimeOffColleaguesActions.INIT_STATE,
        payload
      });
    }

    return () => {
      timeOffColleaguesDispatch({
        type: TimeOffColleaguesActions.INIT_STATE,
        payload: {
          filterBy: {
            customStatuses: defaultState?.filterBy?.customStatuses,
            policies: initialState?.filterBy?.policies,
            period: initialState?.filterBy?.period
          },
          filters: {
            customStatuses: defaultState?.filters?.customStatuses,
            policies: initialState?.filters?.policies,
            period: initialState?.filters?.period
          },
          pagination: {
            resultSize: initialState?.pagination?.resultSize,
            pageNumber: initialState?.pagination?.pageNumber
          }
        }
      });
    };
  }, [window.location.search, payload?.requestId]);

  const { data } = useApiRequest(
    timeOffColleaguesState?.requestId
      ? TimeOffService.getTimeOffRequest
      : TimeOffService.getAllTimeOffRequests, //method
    timeOffColleaguesState?.requestId
      ? {
          requestId: timeOffColleaguesState?.requestId,
          extended: true
        }
      : {
          filterBy: timeOffColleaguesState?.filters,
          pagination: timeOffColleaguesState?.pagination,
          sortBy: defaultState.sortBy
        }, //payload
    [], //initialState
    {}, //parameters
    null, // refetch period
    [
      timeOffColleaguesState.filters,
      timeOffColleaguesState.pagination,
      timeOffColleaguesState.requestId,
      countryCode
    ], // dependecies
    !countryCode, //ignoreHookExecution
    true //ignoreStatusUpdate
  );

  const teamsFilter = data?.['teams']?.map(({ teamId, name }) => ({
    text: name,
    value: teamId
  }));

  const nextApproverFilter = data?.['nextApprovers']?.map(
    ({ employeeId, firstName, lastName, email }) => ({
      text: lastName + ' ' + firstName,
      value: employeeId
    })
  );

  const handleTableChange = (props: TableParams) => {
    const { filters } = props;
    timeOffColleaguesDispatch({
      type: TimeOffColleaguesActions.UPDATE_TABLE_FILTER,
      payload: {
        requestId: null,
        filterBy: {
          requesterName:
            (filters?.['requestedForEmployee']?.[0] as string) ?? null,
          nextApproverIds:
            (filters?.['primaryApproverEmployee'] as Array<number>) ?? null,
          teamIds: (filters?.['teams'] as Array<number>) ?? null
        },
        filters: {
          requesterName:
            (filters?.['requestedForEmployee']?.[0] as string) ?? null,
          nextApproverIds:
            (filters?.['primaryApproverEmployee'] as Array<number>) ?? null,
          teamIds: (filters?.['teams'] as Array<number>) ?? null
        },
        pagination: {
          pageNumber: props.pagination.current
        }
      }
    });
  };

  const { isMobile } = useMobileContext();
  const [modalProps, setModalProps] = React.useState<any>(
    colleagueRequestModalProps
  );

  return (
    <>
      {isMobile ? (
        <ModalComponent
          formKeyId={modalProps.formKeyId}
          children={
            modalProps.formKeyId === 'timeOffForm' ? (
              <TimeOffRequestForm
                key="timeOffRequestForm"
                requestType={modalProps.requestType}
                scope={modalProps.scope}
                timeOffId={modalProps.timeOffId}
              />
            ) : (
              <StateActionsForm
                key="stateActionForm"
                requestId={modalProps.timeOffId}
                modalProps={modalProps}
              />
            )
          }
          submitBtnText={modalProps.submitBtnText}
          cancelBtnText={modalProps.cancelBtnText}
          additionalBtnText={modalProps.additionalBtnText}
          displayFooterAdditionalButton={
            modalProps.displayFooterAdditionalButton
          }
          title={modalProps.modalTitle}
          displayFooterSubmitButton={modalProps.displayRequestButton}
        />
      ) : (
        <ModalComponent
          formKeyId={modalProps.formKeyId}
          children={
            modalProps.formKeyId === 'timeOffForm' ? (
              <TimeOffRequestForm
                key="timeOffRequestForm"
                requestType={modalProps.requestType}
                scope={modalProps.scope}
                timeOffId={modalProps.timeOffId}
                setModalProps={setModalProps}
              />
            ) : (
              <StateActionsForm
                key="stateActionForm"
                requestId={modalProps.timeOffId}
                modalProps={modalProps}
              />
            )
          }
          submitBtnText={modalProps.submitBtnText}
          cancelBtnText={modalProps.cancelBtnText}
          additionalBtnText={modalProps.additionalBtnText}
          displayFooterAdditionalButton={
            modalProps.displayFooterAdditionalButton
          }
          title={modalProps.modalTitle}
          displayFooterSubmitButton={modalProps.displayRequestButton}
        />
      )}
      <ColleaguesTimeOffToolbar
        user={user}
        adminRoles={adminRoles}
        setModalProps={setModalProps}
      />
      <br className="break-line"></br>
      <TimeOffRequestTable
        requestType="colleagues"
        setModalProps={setModalProps}
        responseData={
          timeOffColleaguesState?.requestId && data?.['data']
            ? [data?.['data']]
            : data?.['requests']?.['responseData']
        }
        totalCount={
          timeOffColleaguesState?.requestId
            ? 1
            : data?.['requests']?.['totalCount']
        }
        resultSize={timeOffColleaguesState?.pagination?.resultSize}
        nextApproverFilter={nextApproverFilter}
        teamsFilter={teamsFilter}
        page={timeOffColleaguesState?.pagination?.pageNumber}
        pageSize={timeOffColleaguesState?.pagination?.resultSize}
        setPage={(currentPage: number) => {
          timeOffColleaguesDispatch({
            type: TimeOffColleaguesActions.UPDATE_TABLE_PAGE_NUMBER,
            payload: {
              pagination: {
                pageNumber: currentPage
              }
            }
          });
        }}
        onNrItemsPerPageChange={(size: number) => {
          timeOffColleaguesDispatch({
            type: TimeOffColleaguesActions.UPDATE_TABLE_RESULT_SIZE,
            payload: {
              pagination: {
                resultSize: size
              }
            }
          });
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ColleaguesTimeOffPage;
