import { FormattedMessage, useIntl } from 'react-intl';
import ModalComponent from '../../../../components/Modal';
import { Button, Form, Input } from 'antd';
import ColFormItemComponent from '../../../../components/UIComponents/Form/ColComponent';
import DropdownInputPhone from '../../../../components/UIComponents/InputPhoneComponent/DropdownInputPhone';
import TextArea from 'antd/es/input/TextArea';
import { setFormItemRules } from '../../../../components/UIComponents/Form/ColComponent/utils';
import { usePhoneValidation } from '../../../../hooks/useValidPhoneNumber';
import React, { useState } from 'react';
import {
  onFormFinishFailedValidation,
  onFormFinishWithPassedValidation
} from '../../../../utils/onSubmitForm';
import { useDispatch } from 'react-redux';
import { TeamsService } from '../../../../services';
import { getSuccessMessages } from '../../../../utils/getSuccessMessages';
import { useModalContext } from '../../../../components/Modal/ModalProvider';
import {
  useMobileContext,
  useThemeContext
} from '../../../../AppProvider/ConfigProviderSettings';
import { useConfirmationModalContext } from '../../../../components/Modal/ConfirmModal/ConfirmationModalProvider';

export const ModalAddContact = props => {
  const {
    isModalOpen,
    setIsModalOpen,
    teamId,
    setIsAddMemberModal,
    scope,
    defaultData,
    setDefaultData,
    setScope,
    setConfirmModalContent,
    setOnOkModalPromise,
    setRemoveModalTitle,
    setRemoveModalSuccessMessage
  } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const { toggleModal } = useModalContext();

  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultData?.phoneNumber ? defaultData.phoneNumber : null
  );
  const dispatch = useDispatch();
  const theme = useThemeContext();
  const { isMobile } = useMobileContext();
  const onFinishFormActions = { dispatch, toggleModal };
  if (scope === 'edit') {
    form.setFieldsValue(defaultData);
  }
  const onFinish = (scope, data) => {
    const formActions = {
      create: TeamsService.addTeamContact,
      edit: TeamsService.updateTeamContact
    };

    const getMessage =
      scope === 'add'
        ? getSuccessMessages.success_added_contact
        : getSuccessMessages.success_edited_contact;

    onFormFinishWithPassedValidation(
      formActions,
      scope,
      data,
      onFinishFormActions,
      getMessage
    );
  };

  const { isValid, validatePhone, prepareNumberBeforeSubmit } =
    usePhoneValidation();

  const phoneIntputChange = phoneValue => {
    setPhoneNumber(phoneValue);
    validatePhone(phoneValue);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDefaultData(null);
    setScope('create');
    form.resetFields();
  };
  const { setIsConfirmModalOpen } = useConfirmationModalContext();
  return (
    <ModalComponent
      className="modal-component-employee--add"
      title={
        scope === 'create' ? (
          <FormattedMessage id="Add_contact_modal_title" />
        ) : (
          <FormattedMessage id="Edit_contact_modal_title" />
        )
      }
      open={isModalOpen}
      formKeyId={'AddContactForm'}
      footerButtons={
        isMobile && (
          <Button
            className={`text-bold-normal btn-default-custom  ${theme}`}
            onClick={e => {
              setRemoveModalTitle(
                <FormattedMessage id="RemoveContactModalTitle" />
              );
              setRemoveModalSuccessMessage(
                getSuccessMessages.success_removed_contact
              );
              setIsConfirmModalOpen(true);
              const parameters = {
                id: defaultData?.id
              };

              const removeContact = () =>
                TeamsService.deleteTeamContact(null, parameters);
              setConfirmModalContent(
                intl.formatMessage({
                  id: 'RemoveContactConfirmMessage'
                })
              );

              setOnOkModalPromise(() => removeContact);
              setIsModalOpen(false);
            }}
          >
            <FormattedMessage id="delete" />
          </Button>
        )
      }
      onCancel={handleCancel}
      submitBtnText={
        scope === 'create' ? (
          <FormattedMessage id="add" />
        ) : (
          <FormattedMessage id="save" />
        )
      }
      cancelBtnText={<FormattedMessage id="cancel" />}
      isMaskClosable={false}
      alignSelf="middle"
      afterClose={() => {
        setScope('create');
        setIsAddMemberModal(false);
      }}
      children={
        <Form
          form={form}
          id={'AddContactForm'}
          onFinish={data => {
            onFinish(scope, {
              ...data,
              teamId: teamId,
              phoneNumber: phoneNumber
                ? prepareNumberBeforeSubmit(phoneNumber)
                : '',
              ...(scope === 'edit' && { id: defaultData.id })
            });
          }}
          onFinishFailed={validationError =>
            onFormFinishFailedValidation(validationError, dispatch)
          }
        >
          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Name" />}
            name="name"
            required={true}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'NameIsRequiredValidation' })
              }
            ]}
          >
            <Input minLength={1} />
          </ColFormItemComponent>

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="contact_function" />}
            name="contactFunction"
          >
            <Input maxLength={50} />
          </ColFormItemComponent>

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Company" />}
            name="company"
          >
            <Input maxLength={50} />
          </ColFormItemComponent>

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="colleagues_tableCol_PhoneNumber" />}
            name="phoneNumber"
            rules={[
              ...setFormItemRules({ isRequired: false }),
              {
                validator(_, value) {
                  if (!isValid) {
                    return Promise.reject(
                      intl.formatMessage({
                        id: 'FieldValidationErrorDescription'
                      })
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <DropdownInputPhone
              value={phoneNumber}
              onChange={phoneIntputChange}
            />
          </ColFormItemComponent>

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Email" />}
            name="email"
            rules={[
              {
                type: 'email',
                message: intl.formatMessage({
                  id: 'PleaseProvideEmailValidation'
                })
              }
            ]}
          >
            <Input maxLength={50} />
          </ColFormItemComponent>

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Notes" />}
            name="notes"
          >
            <TextArea maxLength={200} />
          </ColFormItemComponent>
        </Form>
      }
    />
  );
};
